import { captureMessage } from '@sentry/react';

//TODO: Move it outside components doesnt make sense to be inside components
// Note:
// This file should contain constant values that are user throughout

// the application, lets not repeat ourselves
export const staticContent = process.env.REACT_APP_COVALO_STATIC_CONTENT;
//temporary used for pictures
export const staticContentServices =
  process.env.REACT_APP_UXBEAUTY === 'true'
    ? staticContent
    : staticContent?.replace('com', 'services');

export const baseApiSearch = process.env.REACT_APP_COVALO_API_SEARCH;

export const baseApi = process.env.REACT_APP_COVALO_API;
export const legacyApi = process.env.REACT_APP_COVALO_LEGACY_API;
export const mainColor = '#ff6b6b';
export const TOP_PRODUCT_MAXIMUM_LENGTH = 5;

export enum UserStateContextType {
  LOADING = 'LOADING',
  AUTHENTICATED = 'AUTHENTICATED',
  NOT_AUTHENTICATED = 'NOT_AUTHENTICATED'
}

export enum SupplierPortalUserStatus {
  NOT_SIGNED_UP = 'NOT_SIGNED_UP',
  AWAITING_VERIFICATION = 'AWAITING_VERIFICATION',
  VERIFIED = 'VERIFIED',
  BLOCKED = 'BLOCKED'
}

export const IS_UXBEAUTY = process.env.REACT_APP_UXBEAUTY === 'true';

export const WHITELABEL_EVENT_KEY =
  process.env.REACT_APP_COVALO_WHITELABEL_EVENT_KEY;
export const WHITELABEL_COMPANY_KEY =
  process.env.REACT_APP_WHITELABEL_COMPANY_KEY;

export const WHITELABEL_IMAGE_PLACEHOLDER =
  staticContent + '/uxb/backgrounds/DEFAULT_IMAGE.webp';
export const UxBeautyTrendsAndScienceImage =
  staticContent + '/uxb/backgrounds/TrendsAndScience/background.jpg';

export const BrandPortalFilters = [
  { key: 'company_label', label: 'Company labels' },
  { key: 'product_label', label: 'Ingredient labels' }
];

export const RECENTLY_LAUNCHED_FILTERS = [
  { year: '2024', active: false },
  { year: '2023', active: false },
  { year: '2022', active: false }
];

export const currentPlatform = IS_UXBEAUTY ? 'UX-Beauty' : 'Covalo';
// this covalo companies can be changed to a diferent structure if its relevant, just a starting point using an array
export const COVALO_COMPANIES = ['covalo', 'harmona'];

export const NAVBAR_ID = 'nav';
export const PRODUCT_EDITOR_HEADER_ID = 'productHeaderSection';
export const INCOS_EVENTS = (() => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const jsonString = process.env.REACT_APP_INCOS_EVENTS!;
    return JSON.parse(jsonString);
  } catch (error) {
    const errormessage =
      'The INCOS_EVENTS environment variable is missing or contains an invalid JSON format. Please ensure it is a properly formatted JSON array with the correct event keys.';
    // eslint-disable-next-line no-console
    console.error(errormessage);
    captureMessage(errormessage);
    return [];
  }
})();

export const NOTIFICATIONS_REFRESH_INTERVAL = 120000; // slower update of the overall notifications of the website
export const NOTIFICATIONS_REFRESH_INTERVAL_ON_PAGE = 20000; // for when the user is on a page where is nicer to have faster refreshed information
