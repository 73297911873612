import { Button, Menu, MenuItem, Zoom, useTheme } from '@mui/material';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import React, { useContext } from 'react';
import { UserContext } from 'context/User';
import allRoles, { hasAccess, isSupport } from 'helpers/Roles';
import { useTranslation } from 'react-i18next';
import { SingleNavBarItem } from './SingleNavBarItem';
import { useBreakpoints } from 'hooks/useBreakpoints';
interface Props {
  brandPortalRoutes: {
    label: string;
    key: string;
  }[];
  activeLink: string;
  closeOuterMenu?: () => void;
}
export const AdminDropdownMenu: React.FC<Props> = ({
  activeLink,
  brandPortalRoutes,
  closeOuterMenu
}) => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const { width } = useBreakpoints();
  const theme = useTheme();
  const isMobile = width < theme.breakpoints.values.lg;
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    //we just need this on mobile
    closeOuterMenu?.();
  };

  const navOpen = Boolean(anchorElNav);
  const user = useContext(UserContext);
  const { t } = useTranslation();

  const adminRoutes = [
    ...(hasAccess(
      [...allRoles.EDIT_COMPANY, ...allRoles.EDIT_PRODUCTS],
      [],
      user
    )
      ? [
          {
            label: t('nav.items.companies'),
            path: '/admin/companies'
          },
          {
            label: t('nav.items.ingredients'),
            path: '/admin/products/ingredients'
          },
          {
            label: t('nav.items.formulations'),
            path: '/admin/products/formulations'
          },
          {
            label: t('nav.items.services'),
            path: '/admin/products/services'
          }
        ]
      : []),
    ...(isSupport(user)
      ? [
          {
            label: t('nav.items.events'),
            path: '/admin/events'
          },
          {
            label: 'CSM awards dash',
            path: '/admin/csm/awardsDash'
          },
          {
            label: 'CSM Users',
            path: '/admin/csm/users'
          },
          {
            label: 'Subscriptions',
            path: '/admin/subscriptions'
          }
        ]
      : [])
  ];

  return (
    <>
      <MenuItem
        onClick={handleOpenNavMenu}
        sx={theme => ({
          color: theme.palette.text.primary,
          textTransform: 'capitalize',
          paddingRight: 0,
          display: { xs: 'flex', lg: 'none' },
          svg: { color: 'inherit' },
          [`&:hover,active`]: {
            background: 'inherit',
            color: theme.palette.primary.main
          }
        })}
      >
        Admin
        <NavigateNextOutlinedIcon fontSize="small" />
      </MenuItem>
      <Button
        onClick={handleOpenNavMenu}
        variant="text"
        sx={theme => ({
          color: theme.palette.text.primary,
          textTransform: 'capitalize',
          marginRight: -1,
          display: { xs: 'none', lg: 'flex' },
          fontWeight: 400
        })}
      >
        Admin
        <ExpandMoreOutlinedIcon fontSize="small" />
      </Button>
      {anchorElNav && (
        <Menu
          onClose={handleCloseNavMenu}
          anchorEl={anchorElNav}
          open={navOpen}
          MenuListProps={{
            dense: true
          }}
          anchorOrigin={{
            vertical: isMobile ? 'top' : 'bottom',
            horizontal: isMobile ? 'right' : 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          TransitionComponent={Zoom}
        >
          {[
            ...adminRoutes,
            ...brandPortalRoutes.map(event => ({
              ...event,
              path: `/admin/event/${event.key}/dashboard`,
              label: `${event.label} portal`
            }))
          ].map(item => (
            <SingleNavBarItem
              handleMenuClose={handleCloseNavMenu}
              type="menuitem"
              item={item}
              activeLink={activeLink}
              key={item.label}
            />
          ))}
        </Menu>
      )}
    </>
  );
};
