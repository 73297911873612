import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Testimonials, {
  testimonialProps
} from '../Homepage/Sections/Testimonials';
import { staticContent } from 'components/constants/Constants';
import { Bullet } from '../Homepage/Sections/beneficts';
import './buyers.scss';
import { Helmet } from 'react-helmet';
import WatchLater from '@mui/icons-material/WatchLater';
import Language from '@mui/icons-material/Language';
import TrendingUp from '@mui/icons-material/TrendingUp';

const Buyers = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Reduce your time to market.Join more than 5,000 beauty brands "
        />
        <title>Buyers</title>
      </Helmet>
      <div className="page-section page-section-centered flex-column py-5 mt-n2 mb-6">
        <h1>Reduce your time to market</h1>
        <p>Join more than 5,000 beauty brands</p>
        <Link to="/register">
          <Button className="button-pill btn-600">SIGN UP</Button>
        </Link>
      </div>
      <div>
        <PageSection styles={{ backgroundColor: 'white' }}>
          <>
            <h1 className="mb-6">The one stop shop for sustainable sourcing</h1>
            <Row className="mb-6">
              <Col lg="4">
                <div
                  style={{
                    position: 'relative',
                    width: '64.9px',
                    height: '75px',
                    margin: 'auto'
                  }}
                  className="d-flex justify-content-center align-items-center mb-4"
                >
                  <div style={{ position: 'absolute', top: 0 }}>
                    <Bullet width={'64.9px'} height="75px" color="#D9E6E6" />
                  </div>
                  <div style={{ position: 'relative' }}>
                    <WatchLater fontSize="small" />
                  </div>
                </div>
                <p className="beneficts-column-title">Save research time</p>
                <p className="beneficts-column-text">
                  Explore thousands of ingredients and hundreds of service
                  providers in a matter of minutes. Say goodbye to browsing with
                  a million tabs open and lots of manual research.
                </p>
              </Col>
              <Col lg="4" className="text-center">
                <div
                  style={{
                    position: 'relative',
                    width: '64.9px',
                    height: '75px',
                    margin: 'auto'
                  }}
                  className="d-flex justify-content-center align-items-center mb-4"
                >
                  <div style={{ position: 'absolute', top: 0 }}>
                    <Bullet width={'64.9px'} height="75px" color="#D9E6E6" />
                  </div>
                  <div style={{ position: 'relative' }}>
                    <Language fontSize="small" />
                  </div>
                </div>
                <p className="beneficts-column-title">
                  Find exactly what you need
                </p>
                <p className="beneficts-column-text">
                  With various filters like application, claims, sustainability
                  aspects, certification and region, you can drill down to find
                  ingredients and services of your need.
                </p>
              </Col>
              <Col lg="4">
                <div
                  style={{
                    position: 'relative',
                    width: '64.9px',
                    height: '75px',
                    margin: 'auto'
                  }}
                  className="d-flex justify-content-center align-items-center mb-4"
                >
                  <div style={{ position: 'absolute', top: 0 }}>
                    <Bullet width={'64.9px'} height="75px" color="#D9E6E6" />
                  </div>
                  <div style={{ position: 'relative' }}>
                    <TrendingUp fontSize="small" />
                  </div>
                </div>
                <p className="beneficts-column-title">Stay on top of trends</p>
                <p className="beneficts-column-text">
                  Use our real-time ingredient search trends to define your
                  innovation pipeline and to get inspired.
                </p>
              </Col>
            </Row>
          </>
        </PageSection>
        <PageSection styles={{ backgroundColor: '#abc4c4' }}>
          <div style={{ maxWidth: '650px' }}>
            <h1 style={{ color: 'white' }}>How it works</h1>
            <p style={{ color: 'white', textAlign: 'center' }}>
              Covalo uses artificial intelligence to extract data from chemical
              suppliers’ official websites, service provider websites and other
              relevant third-party sites and compiles this data into one
              easy-to-use search engine.
            </p>
            <div
              style={{
                background: `url(${staticContent}/icons/how-it-works-hexagon.b0a8550c.svg) no-repeat calc(50% + 26px) 0`,
                height: '490px',
                width: '100%'
              }}
              className="d-flex align-items-center justify-content-center"
            >
              <img
                src={`${staticContent}/icons/covalo-symbol.svg`}
                width="120px"
                height="auto"
                alt=""
                style={{ color: '#1a535c' }}
              />
            </div>
          </div>
        </PageSection>
        <Testimonials testimonials={buyersTestimonials} />
        <PageSection>
          <h1 className="my-5">
            Develop products more quickly while ensuring compliance and
            sustainability
          </h1>
          {userGuideSteps.map((step: userGuideStepProps, index) => {
            return <UserGuideStep key={index} step={step} />;
          })}
        </PageSection>
        <div
          className="page-section page-section-centered flex-column py-5 mt-4 mb-n6"
          style={{ backgroundColor: 'white' }}
        >
          <h1 className="mb-5">Ready to expedite your product development?</h1>
          <Link to="register">
            <Button className="button-pill btn-600">SIGN UP</Button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Buyers;

interface pagesection {
  children: any;
  styles?: {
    backgroundColor?: string;
  };
}

export const PageSection = (props: pagesection) => {
  return (
    <div
      className="page-section page-section-centered flex-column py-5 mt-n4"
      style={{
        backgroundColor: props?.styles?.backgroundColor ?? 'inherit'
      }}
    >
      <div className="page-section-wrapper">{props.children}</div>
    </div>
  );
};

export interface userGuideStepProps {
  img: string;
  title: string;
  points: string[];
}

const userGuideSteps: userGuideStepProps[] = [
  {
    img: `${staticContent}/staticPagesAssets/brands-img-1.bab7b66d.webp`,
    title: 'Access a network of 75,000+ ingredients and service companies',
    points: [
      'Join one of the largest largest sourcing platforms for beauty industry buyers',
      'Quickly generate a short list of supplier candidates - we aim to provide ample information to you up front about each company and their products so you can tell if they fit your needs',
      "Access information about products that is always up-to-date (automatically pulled from suppliers' websites)",
      'Contact suppliers directly through our secure messaging to make collaborating simple and convenient (coming soon)'
    ]
  },
  {
    img: `${staticContent}/staticPagesAssets/brands-img-2.21de39ee.webp`,
    title: 'Quickly find the right partners and stay on top of trends',
    points: [
      'Bookmark and compare ingredients',
      'Efficiently send batch Request For Quotations (RFQ) to multiple companies that suit your specific needs',
      'Request samples easily using our structured template',
      'Discover real-time trends in terms of what other buyers are searching for regarding ingredients, manufacturers, etc.',
      'Stay on top of market trends through the Covalo blog',
      "Ensure compliance by checking each company and products' certifications",
      'Prioritize sustainability and performance by using our sustainability and claims filters when exploring products'
    ]
  }
];

export const UserGuideStep = ({ step }: { step: userGuideStepProps }) => {
  return (
    <div style={{ maxWidth: '900px', margin: '1.6rem auto' }}>
      <div className="user-guide-title-wrapper">
        <span></span>
        <p>{step.title}</p>
      </div>
      <div className="user-guide-points-wrapper">
        <img src={step.img} alt="" />
        <ul>
          {step.points.map((point: string, key: number) => (
            <li key={key}>{point}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export const buyersTestimonials: testimonialProps[] = [
  {
    companyImg: `${staticContent}/homepage/testimonials/logo-AquaBond.webp`,
    userName: 'Cristina Resetco',
    userPosition: 'Business Development Manager @ Aqua Bond',
    testimonial: `"Covalo has helped us find and source new raw materials quickly and easily. As a contract manufacturer, Aqua Bond was able to source new raw materials and launch products faster with the help of Covalo. The database of the products and suppliers is very large, so it covers all the main sources and options that we need. I’m glad to have this as a quick resource for product development and purchasing."`
  },
  {
    companyImg: `${staticContent}/homepage/testimonials/logo-ekspresyonnaturel.webp`,
    userName: 'Myrlande Holborough',
    userPosition: 'Founder & Formulator @ Ekspresyon Naturel Ltd',
    testimonial: `"Covalo has been a great source for access to suppliers and ingredients that would have been quite difficult for a new indie brand such as mine. The staff is extremely supportive and helpful. It's been amazing to have this service available."`
  },
  {
    companyImg: `${staticContent}/homepage/testimonials/logo-bettyhula.webp`,
    userName: 'Lisa Swaine',
    userPosition: 'Managing Partner @ Betty Hula',
    testimonial: `"As a small natural skincare company, having access at our fingertips to where to get the ingredients for new formulations is so helpful. We discovered our now main supplier through Covalo."`
  }
];
