import classNames from 'classnames';
import { lazy, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import { tss } from 'tss-react/mui';
const Footer = lazy(() => import('components/common/Footer/Footer'));

const MainLayout: React.FC<{
  component: JSX.Element;
  hasWhiteBackground?: boolean;
  noVerticalPadding?: boolean;
  preventDoubleScrollbars?: boolean;
  disableTopMargin?: boolean;
  noHorizontalPadding?: boolean;
}> = ({
  component,
  hasWhiteBackground,
  noVerticalPadding,
  preventDoubleScrollbars,
  disableTopMargin,
  noHorizontalPadding
}) => {
  const { pathname } = useLocation();

  const { classes } = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <div
        className={classNames([
          { 'container-fluid': !preventDoubleScrollbars && !disableTopMargin },
          { 'bg-white': hasWhiteBackground }
        ])}
      >
        <div
          className={
            preventDoubleScrollbars || noHorizontalPadding
              ? classes.adminContent
              : 'content'
          }
        >
          <NavigationBar
            withoutMargins={
              preventDoubleScrollbars || disableTopMargin ? true : false
            }
          />
          {preventDoubleScrollbars ? (
            component
          ) : (
            <div className={!noVerticalPadding ? `px-3` : undefined}>
              {component}
            </div>
          )}
        </div>
      </div>
      {!preventDoubleScrollbars && <Footer />}
    </>
  );
};

export default MainLayout;

const useStyles = tss.create({
  adminContent: {
    minHeight: '100vh',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  }
});
