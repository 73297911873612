import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Zoom
} from '@mui/material';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import React from 'react';
import LoginIcon from '@mui/icons-material/Login';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { Trans } from 'react-i18next';
export const MobileProfileIcon = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const navOpen = Boolean(anchorElNav);

  return (
    <>
      <IconButton
        sx={{ display: { xs: 'flex', lg: 'none' } }}
        onClick={handleOpenNavMenu}
        aria-label="Open profile menu"
      >
        <PersonIcon />
      </IconButton>
      <Menu
        anchorEl={anchorElNav}
        open={navOpen}
        onClose={handleCloseNavMenu}
        TransitionComponent={Zoom}
      >
        <MenuItem
          onClick={() => {
            handleCloseNavMenu();
            auth.signinRedirect();
          }}
        >
          <ListItemIcon>
            <LoginIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Trans i18nKey="auth.login" />
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseNavMenu();
            navigate('/register');
          }}
        >
          <ListItemIcon>
            <BorderColorOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <Trans i18nKey="auth.signup" context="free" />
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
