import { readNotification } from 'api/services/NotificationsService';
import { getDownloadUrl } from 'api/services/ProjectsService';
import { toast } from 'react-toastify';
import {
  isBrandPortalAction,
  NotificationsActions
} from './notificationsTypes';
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import Chat from '@mui/icons-material/Chat';
import Inbox from '@mui/icons-material/Inbox';
import LocalOffer from '@mui/icons-material/LocalOffer';
import Download from '@mui/icons-material/Download';
import AttachMoney from '@mui/icons-material/AttachMoney';
import Person from '@mui/icons-material/Person';
import { Button, TableCell, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useGetBrandPortalInfo } from 'hooks/useGetBrandPortalInfo';

type NotificationData = {
  [key: string]: any;
  request?: { key: string };
};

type NotificationProps = {
  identifier: string;
  action: NotificationsActions;
  read: boolean;
  triggered_at: string;
  toggle: () => void;
  data: NotificationData;
  event_key?: string;
};

const Notification: React.FC<NotificationProps> = ({
  identifier,
  action,
  read,
  triggered_at,
  toggle,
  data,
  event_key
}) => {
  const notificationIcon = getNotificationIcon(action);
  const today = new Date();
  const date =
    today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();

  const notificationDate = getNotificationDate(triggered_at, date);

  const brandPortalInfo = useGetBrandPortalInfo(event_key);

  const markAsRead = (id: any) => {
    readNotification(
      id,
      () => null,
      () =>
        toast.error(
          "Oops, something went wrong, couldn't mark notification as read"
        )
    );
  };

  const nav = useNavigate();
  return (
    <TableRow
      hover
      sx={() => ({
        '&:last-child td, &:last-child th': { border: 0 },
        backgroundColor: read ? undefined : `#ff888814 !important`
      })}
      onClick={() => {
        data.request?.key && nav(redirectLinks(action, data.request.key));
        toggle && toggle();
        markAsRead(identifier);
      }}
    >
      <TableCell sx={{ display: 'flex' }}>
        <div
          className="notification-avatar me-3 d-flexfw-semi-bold"
          style={{ borderRadius: '2px' }}
        >
          {notificationIcon && notificationIcon}
        </div>

        <div className="notification-body ">
          <p className="mb-0" style={{ lineHeight: '1.3em' }}>
            {getNotificationDescription(action, data)}
          </p>
          <div className="mb-2">
            {displayNotificationInteraction(
              action,
              data,
              brandPortalInfo?.name
            )}
            <span className="notification-time">
              {notificationDate}
              {isBrandPortalAction(action)
                ? ` - from ${brandPortalInfo?.name} portal`
                : ''}
            </span>
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default Notification;

function redirectLinks(action: NotificationsActions, id: string) {
  switch (action) {
    case NotificationsActions.documentUploaded:
      return `/contacts/${id}`;
    case NotificationsActions.newMessage:
      return `/contacts/${id}`;
    case NotificationsActions.contactCreated:
      return `/contacts/${id}`;
    case NotificationsActions.downloadReady:
      return ``;
    default:
      return id ? `/contacts/${id}` : '';
  }
}
function getNotificationIcon(action: any) {
  switch (action) {
    case NotificationsActions.documentUploaded:
      return <InsertDriveFile />;
    case NotificationsActions.newMessage:
      return <Chat />;
    case NotificationsActions.contactCreated:
      return <Inbox />;
    case NotificationsActions.downloadReady:
      return <Download />;
    case NotificationsActions.quoteCanceled:
    case NotificationsActions.quoteCreated:
    case NotificationsActions.quoteEdited:
    case NotificationsActions.quoteExtended:
      return <AttachMoney />;
    case NotificationsActions.labelCreated:
    case NotificationsActions.labelUpdated:
    case NotificationsActions.labelRemoved:
      return <LocalOffer />;
    case NotificationsActions.userAddedToPortal:
      return <Person />;
    default:
      break;
  }
}

const renderCommonNotification = (data: any, message: any, options?: any) => {
  const user = options?.user;
  return (
    <span>
      <span className="fw-semi-bold">
        {user === undefined
          ? data?.created_by?.firstname
            ? data?.created_by?.firstname
            : data?.sent_by?.firstname
          : `${data.triggered_by?.firstname} from ${data[user].name}`}
      </span>
      {` ${message} `}
      <span className="fw-semi-bold">{data?.product?.name}</span>
      {message.includes('validity') ? ` to ${data.quote.validity} ` : ''}
    </span>
  );
};
const brandPortalLabelNotifications = (
  action: NotificationsActions,

  eventName: string,
  /**
   *  company name for company action supplier name for product action
   */
  suppliername: string,
  triggerer: any,
  /**
   *  label name when label is created and the new label name changed to
   */
  newLabelName: string,
  /**
   *  label name when label is deleted and the old label name changed from
   */
  oldLabelName: string,
  ingredientName: string
) => {
  switch (action) {
    case NotificationsActions.labelCreated:
    case NotificationsActions.labelRemoved:
      return (
        <div>
          {eventName === 'COMPANY_LABEL_CHANGED'
            ? 'Company Label '
            : 'Ingredient Label '}
          <span className="fw-bold">
            {action === NotificationsActions.labelCreated
              ? newLabelName
              : oldLabelName}
          </span>
          {action === NotificationsActions.labelCreated
            ? ` was added to `
            : ' was removed from '}
          <span className="fw-bold">{suppliername}</span>
          {` by `}
          <span className="fw-bold">{triggerer}</span>
        </div>
      );

    case NotificationsActions.labelUpdated:
      return (
        <div>
          {eventName === 'COMPANY_LABEL_CHANGED'
            ? 'Company Label '
            : 'Ingredient Label '}
          {` was changed from  `}
          <span className="fw-bold">{oldLabelName}</span>
          {' to '}
          <span className="fw-bold">{newLabelName}</span>
          {' for '}
          <span className="fw-bold">
            {suppliername}
            {eventName === 'PRODUCT_LABEL_CHANGED' &&
              `'s ${ingredientName.replace(/[()]/g, '')}`}
          </span>
          {` by `}
          <span className="fw-bold">{triggerer}</span>
        </div>
      );
  }
};

function getNotificationDescription(action: string, data: NotificationData) {
  switch (action) {
    case NotificationsActions.documentUploaded:
      return (
        <span>
          New documents uploaded for{' '}
          <span className="fw-semi-bold">{data?.product?.name}</span>{' '}
        </span>
      );
    case NotificationsActions.newMessage:
      return renderCommonNotification(data, 'sent a new message for');
    case NotificationsActions.contactCreated:
      return renderCommonNotification(data, 'has created a new request for');
    case NotificationsActions.downloadReady:
      return (
        <span>
          <span className="fw-semi-bold">
            {data?.created_by?.firstname
              ? data?.created_by?.firstname
              : data?.sent_by?.firstname}
          </span>{' '}
          File(s) requested for download on project{' '}
          <span className="fw-semi-bold">{data.project.title} </span> are ready
          for download.
        </span>
      );
    case NotificationsActions.quoteCanceled:
      return renderCommonNotification(data, 'canceled a quote for', {
        user: 'supplier'
      });
    case NotificationsActions.quoteCreated:
      return renderCommonNotification(data, 'created a quote for', {
        user: 'supplier'
      });
    case NotificationsActions.quoteEdited:
      return renderCommonNotification(data, 'updated a quote for', {
        user: 'supplier'
      });
    case NotificationsActions.quoteExtended:
      return renderCommonNotification(
        data,
        'extended the validity of a quote for',
        { user: 'supplier' }
      );
    case NotificationsActions.quoteAccepted:
      return renderCommonNotification(data, 'accepted your quote for', {
        user: 'buyer'
      });
    case NotificationsActions.quoteRejected:
      return renderCommonNotification(data, 'rejected your quote for', {
        user: 'buyer'
      });
    default:
      break;
  }
}

function displayNotificationInteraction(
  action: any,
  data: any,
  eventName?: string
) {
  switch (action) {
    case NotificationsActions.documentUploaded:
      return data.documents.map((document: any, index: any) => {
        const isLast = data.documents.length - 1 === index;
        return (
          <span key={index}>
            {document.name}{' '}
            <span>
              ({document.type}){!isLast && ', '}
            </span>
          </span>
        );
      });
    case NotificationsActions.newMessage:
      return <div className="notification-message">{data.message}</div>;
    case NotificationsActions.userAddedToPortal:
      return (
        <div>
          <span className="fw-bold">
            {data.invitee.firstname + ' ' + data.invitee.lastname}
          </span>
          {` has accepted your invitation to join Covalo and the ${
            eventName ?? ''
          } brand portal`}
        </div>
      );

    case NotificationsActions.labelCreated:
    case NotificationsActions.labelRemoved:
    case NotificationsActions.labelUpdated:
      return brandPortalLabelNotifications(
        action,
        data.event_name,
        data.supplier?.name || data.company?.name,
        data.triggered_by.firstname + ' ' + data.triggered_by.lastname,
        data?.new_label_name || '',
        data?.old_label_name || '',
        data?.product?.name || ''
      );

    case NotificationsActions.downloadReady:
      return (
        <div className="my-2">
          <Button
            onClick={e => {
              e.preventDefault();
              getDownloadUrl(
                data.company.key,
                data.project.path,
                data.download.key,
                (error: any) => {
                  toast.error(error.message);
                }
              );
            }}
            variant="outlined"
            size="small"
          >
            Download
          </Button>
        </div>
      );

    default:
      break;
  }
}

function getNotificationDate(created_at: any, date: any) {
  if (!created_at) return 'null';
  created_at = new Date(created_at);
  const notificationDate =
    created_at.getDate() +
    '-' +
    (created_at.getMonth() + 1) +
    '-' +
    created_at.getFullYear();
  if (String(notificationDate) === String(date)) {
    // its today
    const hour = created_at.getHours();
    let minutes =
      created_at.getMinutes() > 9
        ? created_at.getMinutes()
        : 0 + created_at.getMinutes();
    minutes = minutes > 9 ? minutes : '0' + minutes;
    return 'Today at ' + hour + ':' + minutes;
  } else {
    return notificationDate;
  }
}
