interface BulletProps {
  color?: string;
  height?: string;
  width?: string;
}
export const Bullet = ({ color, height, width }: BulletProps) => {
  return (
    <svg
      className="hex-button-background"
      style={{ width: 'fit-content' }}
      width={width ?? '17px'}
      height={height ?? '20px'}
      viewBox={'0 0 213 246'}
    >
      <defs>
        <linearGradient
          className={'gradient'}
          x1="76.5%"
          y1="0%"
          x2="23.5%"
          y2="100%"
        >
          <stop className={'from'} offset="0%" />
          <stop className={'to'} offset="100%" />
        </linearGradient>
      </defs>
      <polygon
        className="bg-base"
        points="106.5 0 213 61.5 213 184.5 106.5 246 0 184.5 0 61.5"
      />
      <polygon
        className="button-background"
        points="106.5 0 213 61.5 213 184.5 106.5 246 0 184.5 0 61.5"
        fill={color ?? `#FFE66D`}
      />
    </svg>
  );
};
