interface testimonialsProps {
  testimonials: testimonialProps[];
}
const Testimonials = ({ testimonials }: testimonialsProps) => {
  return (
    <div className="homepage-section-white" style={{ padding: '5rem 0 6rem' }}>
      <div className="homepage-section-inner-wrapper">
        <h1 className="text-center " style={{ fontWeight: '400' }}>
          What our users are saying
        </h1>
        <div className="testimonial-list">
          {testimonials.map((testimonial: testimonialProps) => {
            return (
              <Testimonial
                testimonial={testimonial}
                key={testimonial.userName}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;

const Testimonial = (testimonial: { testimonial: testimonialProps }) => {
  return (
    <div className="testimonial">
      <div className="logo-company">
        <img src={testimonial.testimonial.companyImg} alt="" />
      </div>
      <p className="testimonial-text person-name">
        {testimonial.testimonial.userName}
      </p>
      <p className="testimonial-text person-role">
        {testimonial.testimonial.userPosition}
      </p>
      <p className="testimonial-text quote">
        {testimonial.testimonial.testimonial}
      </p>
    </div>
  );
};

export interface testimonialProps {
  companyImg: string;
  userName: string;
  userPosition: string;

  testimonial: string;
}
