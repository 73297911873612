import { getUser } from 'auth/AuthenticationProvider';
import axios from 'axios';
import { toast } from 'react-toastify';
import { captureMessage } from '@sentry/react';

/**
 * @deprecated Use the TypeScript file instead
 */
export const requestUnauthenticated = (options, success, handleError) => {
  axios
    .request(options)
    .then(function (response) {
      success(response);
    })
    .catch(function (error) {
      if (handleError) {
        handleError(error);
      }
    });
};

/**
 * @deprecated Use the TypeScript file instead, preferrably in combination with `useApi`
 */
export const request = (
  options,
  success,
  handleError,
  toastOptions,
  customErrorMessage = false,
  suppressSentryErrorOnFailure = false
) => {
  if (!options['headers']) {
    options['headers'] = {};
  }

  const token = getUser()?.access_token;
  if (token) {
    options['headers']['Authorization'] = `Bearer ${token}`;
  }

  let toastId;
  let promise = () =>
    new Promise((resolve, reject) =>
      axios.request(options).then(
        function (response) {
          success(response);
          resolve();
        },
        function (error) {
          if (toastOptions && !toastOptions?.error && !customErrorMessage) {
            toast.dismiss(toastId);
            toast.error(
              error?.response?.data?.detail ??
                error?.message ??
                'Something went wrong'
            );
          }
          if (handleError) {
            handleError(error);
          }

          if (!suppressSentryErrorOnFailure) {
            captureMessage('Network request failed...', {
              tags: {
                errorCode: error.code,
                statusCode: error.response?.status,
                requestUrl: error.config?.url,
                originFile: 'apiJS'
              },
              level: 'warning'
            });
          }
          reject(error);
        }
      )
    );

  return (
    toastOptions ? toast.promise(promise, toastOptions) : promise()
  ).catch(() => {
    // We don't want to have unhandled promise rejection errors in the console.
    // It's possible to handle the error in a user friendly way via handleError.
  });
};

/**
 * @deprecated Use the TypeScript file instead
 */
export const requestRedirectDownload = (options, handleError) => {
  if (!options['headers']) {
    options['headers'] = {};
  }
  options['headers']['Authorization'] = `Bearer ${getUser()?.access_token}`;
  const headers = options.headers;
  fetch(options.url, { headers })
    .then(res => window.open(res.url, '_blank'))
    .catch(function (error) {
      if (handleError) {
        handleError(error);
      }
    });
};
