import {
  Box,
  Button,
  Menu,
  MenuItem,
  ListItemText,
  Divider,
  Zoom,
  IconButton
} from '@mui/material';
import { getNotifications } from 'api/services/NotificationsService';
import { NoNotifications } from 'components/notification/NoNotifications';
import Notification from 'components/notification/Notification';
import { removeSomeNotifications } from 'components/pages/Notifications/Notifications';
import { NotificationsContext } from 'context/Notifications';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { Badge } from '@mui/material';
import { Notification as TNotification } from 'components/notification/types';
import { useNavigate } from 'react-router-dom';
import Download from '@mui/icons-material/Download';

const NotificationDropdown = () => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const navOpen = Boolean(anchorElNav);
  const [noNotifications, setNoNotifications] = useState(true);
  const [notifications, setNotifications] = useState<TNotification[]>([]);
  const notificationsContext = useContext(NotificationsContext);
  const [persistentNotifications, setPersistentNotifications] = useState(false);

  const navigate = useNavigate();

  const grabNotifications = useCallback(() => {
    if (notificationsContext.notifications) {
      // if there is new notifications then show the little dot on top of the icon of the Bar, toggle class  notification-indicator
      const newNotifications = getSlicedNotifications(
        notificationsContext.notifications
      );

      const hasDownloadDocumentNotification =
        newNotifications.length > 0
          ? newNotifications?.every(
              (notification: { data: { type: string } }) =>
                notification.data.type === 'download'
            )
          : false;
      if (hasDownloadDocumentNotification) {
        setPersistentNotifications(true);
        setTimeout(() => {
          setPersistentNotifications(false);
        }, 2500);
      }

      //TODO: check if data is really new otherwise dont add
      if (newNotifications.length > 0) {
        setNoNotifications(false);
      } else {
        setNoNotifications(true);
      }
      setNotifications(newNotifications);
    }
  }, [notificationsContext]);

  useEffect(() => {
    grabNotifications();
  }, [grabNotifications]);

  useEffect(() => {
    setPersistentNotifications(false);
    if (navOpen) {
      getNotifications(
        '?sort=triggered_at,desc&size=101',
        (response: { data: { content: TNotification } }) => {
          const contentFiltered = removeSomeNotifications(
            response.data.content
          );
          const newNotifications = getSlicedNotifications(contentFiltered);
          if (newNotifications.length > 0) {
            setNoNotifications(false);
          } else {
            setNoNotifications(true);
          }
          setNotifications(newNotifications);
        },
        () => toast.error('Could not get notifications')
      );
    }
  }, [navOpen]);

  const getSlicedNotifications = (data: TNotification[]) => {
    const onlyUnread = data?.filter(
      (notification: { read: boolean }) => notification.read === false
    );
    if (onlyUnread?.length <= 0 && data?.length <= 0) {
      setNoNotifications(true);
      // // IF THERE ARE NO UNREAD NOTIFICATIONS THEN SHOW THE MOST RECENT ALREADY READ MESSAGES
      // onlyUnread = notificationsData
    }
    const maxNotifications = 5;
    const slicedNotifications = onlyUnread?.slice(0, maxNotifications);
    return slicedNotifications;
  };
  return (
    <>
      <IconButton onClick={handleOpenNavMenu}>
        <Badge color="primary" badgeContent={notifications?.length}>
          <NotificationsOutlinedIcon />
        </Badge>
      </IconButton>
      {anchorElNav && (
        <Menu
          anchorEl={anchorElNav}
          onClose={handleCloseNavMenu}
          open={navOpen}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: -12,
            horizontal: 'left'
          }}
          TransitionComponent={Zoom}
        >
          <Box
            sx={{
              backgroundColor: 'white',
              width: 300,
              position: 'relative'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <MenuItem
                disabled
                sx={{
                  '&.Mui-disabled': {
                    opacity: 1
                  }
                }}
              >
                <ListItemText>Notifications</ListItemText>
              </MenuItem>

              {notifications?.length !== 0 && !noNotifications && (
                <Button
                  variant="text"
                  color="primary"
                  sx={theme => ({
                    fontSize: theme.typography.caption.fontSize,
                    textTransform: 'none'
                  })}
                  onClick={() => notificationsContext.markAsRead()}
                >
                  Mark all as read
                </Button>
              )}
            </Box>
            <Divider />

            {noNotifications ? (
              <NoNotifications message="No new notifications to display" />
            ) : (
              <>
                <ListGroup
                  variant="flush"
                  className="fw-normal fs--1 scrollbar"
                  style={{ maxHeight: '19rem' }}
                >
                  {notifications &&
                    notifications.map((notification, index) => (
                      <Notification
                        {...notification}
                        identifier={notification.key}
                        key={index}
                        toggle={handleCloseNavMenu}
                      />
                    ))}
                </ListGroup>
              </>
            )}
            <Divider />
            <MenuItem
              onClick={() => {
                navigate('/notifications');
                handleCloseNavMenu();
              }}
            >
              <ListItemText sx={{ textAlign: 'center' }}>View all</ListItemText>
            </MenuItem>
          </Box>
        </Menu>
      )}

      {persistentNotifications && (
        <div style={{ position: 'relative' }}>
          <div className={`downloads-ready-indication `}>
            <Download />
            <p className="m-0 mt-2">There are files ready for download.</p>
          </div>
        </div>
      )}
    </>
  );
};

export default NotificationDropdown;
